import { Routes, Route, Outlet } from "react-router-dom";
import Navigation from "./routes/navigation/navigation.component";
import Home from "./routes/home/home.component";

import "./App.scss";
import CharacterSearch from "./routes/character-search/character-search.component";
import Authenitication from "./routes/authenitication/authenitication.component";
import CharacterDetailPage from "./routes/character-detail/character-detail.component";
import CharacterSearchAll from "./routes/character-search/character-search-all.component";
import StorySearch from "./routes/story-search/story-search.component";
import StorySearches from "./routes/story-searches/story-searches.component";
import StoryDetailPage from "./routes/story-detail/story-detail.component";
import Fall22 from "./routes/fall22/fall22.component";
import Roleplay from "./routes/roleplay/roleplay.component";
import MyRoleplays from "./routes/roleplay-search/roleplay-search.component";
import RoleplaySetting from "./routes/roleplay-setting/roleplay-setting.component";
import ChangeLog from "./routes/changelog/changelog.route";
import EventSearch from "./routes/idea-events/event-search.route";
import EventSearches from "./routes/idea-events/event-searches.route";
import EventDetailPage from "./routes/idea-events/event-detail.route";
import { useState, useEffect } from "react";
import { retrieveSiteSettings } from "./utils/firebase/firebase.utils";
import TestEventDetailPage from "./routes/testRoutes/event-detail.route";

const App = () => {
  //let { path } = useRouteMatch();
  const [settings, setSettings] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (settings.length === 0) {
      const fetchSettings = async () => {
        setSettings(await retrieveSiteSettings());
      };
      fetchSettings();
    }
  }, []);

  return (
    <Routes>
      <Route
        path="test"
        element={
          <div>
            <Outlet />
          </div>
        }
      >
        <Route
          path="/test/:testId"
          element={
            <TestEventDetailPage
              settings={settings}
              events={events}
              setEvents={setEvents}
            />
          }
        ></Route>
      </Route>
    </Routes>
  );
};

export default App;
